import { useState } from "react"

import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"
import { WorkflowStatus, getWorkflowStatus } from "lib/workflows"
import { Box, HStack, Text } from "@chakra-ui/react"

import Table from "../Tables/Table"
import ExpertDeterminationRow, { ExtendedWorkflow } from "./ExpertDeterminationRow"
import MultiSelectDropdown from "../MenusAndModals/MultiSelect"
import { H4 } from "../Text"

interface StatusSelectorProps {
  selectedStatuses: WorkflowStatus[]
  onStatusChange: (statuses: WorkflowStatus[]) => void
}

const humanizedStatuses: Record<WorkflowStatus, string> = {
  [WorkflowStatus.IN_PROGRESS]: "In Progress",
  [WorkflowStatus.ACTIVE]: "Active",
  [WorkflowStatus.INACTIVE]: "Inactive",
  [WorkflowStatus.CANCELLED]: "Cancelled",
}

const StatusSelector = ({ selectedStatuses, onStatusChange }: StatusSelectorProps) => {
  const options = Object.values(WorkflowStatus).map((status) => ({
    label: humanizedStatuses[status],
    value: status,
  }))

  return (
    <HStack spacing={2} align="center">
      <H4 style={{ color: "var(--text2)" }}>Status</H4>
      <MultiSelectDropdown availableOptions={options} onChange={(value) => onStatusChange(value as WorkflowStatus[])} value={selectedStatuses} compact={true} />
    </HStack>
  )
}

const NoExpertDeterminations = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="left" justifyContent="left" padding="24px">
      <Text color="var(--gray-2)">No expert determinations matching your filters</Text>
    </Box>
  )
}

const ExpertDeterminationsTable = ({ expertDeterminations }: { expertDeterminations: ExtendedWorkflow[] }) => {
  const [selectedStatuses, setSelectedStatuses] = useState<WorkflowStatus[]>([WorkflowStatus.IN_PROGRESS, WorkflowStatus.ACTIVE])
  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")
  const rbacEnabled = flagState?.state["role-based-access-control-july-11"]
  const statusChangesEnabled = flagState?.state["workflow-status-changes-feb-6"]

  const filteredDeterminations = statusChangesEnabled && selectedStatuses.length > 0 ? expertDeterminations.filter((ed) => selectedStatuses.includes(getWorkflowStatus(ed))) : expertDeterminations

  const statusHeader = statusChangesEnabled ? { label: "Status", customHeader: <StatusSelector selectedStatuses={selectedStatuses} onStatusChange={setSelectedStatuses} /> } : { label: "Status" }

  const tableHeaders = [{ label: "Use Case" }, ...(rbacEnabled ? [{ label: "Created by" }] : []), statusHeader, { label: "Combined Datasets" }, { label: "Last Updated" }, { label: "Report" }]

  return (
    <Table columnHeaders={tableHeaders} headerIndent="24px" emptyState={{ tableBody: <NoExpertDeterminations />, showHeader: true }}>
      {filteredDeterminations.map((expertDetermination) => (
        <ExpertDeterminationRow key={expertDetermination?.id} expertDetermination={expertDetermination} />
      ))}
    </Table>
  )
}

export default ExpertDeterminationsTable
