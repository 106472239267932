import { Box, Badge as ChakraBadge, HStack } from "@chakra-ui/react"
import { FiInfo } from "react-icons/fi"

import Tooltip from "./Tooltip"

type BadgeColorSchemeProps = {
  bg: string
  color: string
}

export enum BadgeColor {
  GREEN = "green",
  ORANGE = "orange",
  RED = "red",
  YELLOW = "yellow",
  BLUE = "blue",
  GRAY = "gray",
  EXTREME_GREEN = "extremeGreen",
  EXTREME_ORANGE = "extremeOrange",
  EXTREME_RED = "extremeRed",
}

const badgeColorScheme: Record<BadgeColor, BadgeColorSchemeProps> = {
  [BadgeColor.GREEN]: {
    bg: "var(--green-1)",
    color: "var(--green-2)",
  },
  [BadgeColor.EXTREME_GREEN]: {
    bg: "#38A169",
    color: "white",
  },
  [BadgeColor.ORANGE]: {
    bg: "var(--orange-1)",
    color: "var(--orange-2)",
  },
  [BadgeColor.EXTREME_ORANGE]: {
    bg: "#FF8535",
    color: "white",
  },
  [BadgeColor.RED]: {
    bg: "var(--red-1)",
    color: "var(--red-2)",
  },
  [BadgeColor.EXTREME_RED]: {
    bg: "#E53E3E",
    color: "white",
  },
  [BadgeColor.YELLOW]: {
    bg: "var(--yellow-1)",
    color: "var(--yellow-2)",
  },
  [BadgeColor.BLUE]: {
    bg: "var(--blue-1)",
    color: "var(--blue-2)",
  },
  [BadgeColor.GRAY]: {
    bg: "var(--gray-1)",
    color: "var(--gray-2)",
  },
}

interface BadgeProps {
  color: BadgeColor
  children: React.ReactNode
  tooltip?: string | null
  [key: string]: any
}

export const Badge = ({ color, children, tooltip, ...props }: BadgeProps) => {
  return (
    <Tooltip label={tooltip}>
      <ChakraBadge fontSize="14px" borderRadius="2px" px="6px" py="2px" fontWeight="500" background={badgeColorScheme[color]["bg"]} color={badgeColorScheme[color]["color"]} {...props}>
        <HStack>
          <Box>{children}</Box>
          {tooltip && <FiInfo />}
        </HStack>
      </ChakraBadge>
    </Tooltip>
  )
}
