import { useState } from "react"

import dayjs from "dayjs"
import { Box, Text, useDisclosure, Textarea, Spacer } from "@chakra-ui/react"
import { CertificationDocument, Workflow } from "@prisma/client"
import { Badge } from "src/components/Indicators/Badge"
import { BadgeColor } from "src/components/Indicators/Badge"
import { getWorkflowStatus, WorkflowStatus } from "lib/workflows"
import { updateWorkflow } from "pages/api/models/workflows/mutations/updateWorkflow"
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"
import revalidateSWRQueryByCacheKey from "src/helpers/revalidateSWRQueryByCacheKey"

import Tooltip from "../Indicators/Tooltip"
import BasicModal from "../MenusAndModals/BasicModal"

const EXPIRES_SOON_DAYS = 90
const MAX_CANCELLATION_REASON_LENGTH = 300

const badgeColors: Record<WorkflowStatus, BadgeColor> = {
  [WorkflowStatus.IN_PROGRESS]: BadgeColor.BLUE,
  [WorkflowStatus.ACTIVE]: BadgeColor.GREEN,
  [WorkflowStatus.INACTIVE]: BadgeColor.GRAY,
  [WorkflowStatus.CANCELLED]: BadgeColor.GRAY,
}

const CancellationModal = ({ onClose, workflowId }: { onClose: () => void; workflowId: number }) => {
  const [reason, setReason] = useState("")
  const isInvalid = !reason || reason.length > MAX_CANCELLATION_REASON_LENGTH

  return (
    <BasicModal
      size="xl"
      title="Cancel Workflow"
      onClose={() => onClose()}
      showActionButtons
      actionText="Confirm Cancellation"
      onAction={async () => {
        await updateWorkflow({ id: workflowId, params: { isCancelled: true, cancellationReason: reason } })

        await revalidateSWRQueryByCacheKey("app:getWorkflowsByUser")

        onClose()
      }}
      isActionLoading={false}
      isActionDisabled={isInvalid}
      showCancelButton={false}
    >
      <Spacer height="8px" />
      <Text>Cancelled workflows will still be viewable, but considered inactive. Cancelling a workflow cannot be undone. </Text>
      <Spacer height="24px" />
      <label style={{ fontWeight: "500" }}>Describe why you are cancelling this workflow.</label>
      <Textarea mt="8px" placeholder="eg: This was a placeholder to test Integral features" value={reason} onChange={(e) => setReason(e.target.value)} isInvalid={isInvalid} />
      {isInvalid && (
        <Text color="red.500" fontSize="sm" mt="2">
          {MAX_CANCELLATION_REASON_LENGTH} char limit
        </Text>
      )}
    </BasicModal>
  )
}

const ExpertDeterminationStatusBadge = ({ workflow }: { workflow: Workflow & { certificationDocument: CertificationDocument } }) => {
  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")
  const statusChangesEnabled = flagState?.state["workflow-status-changes-feb-6"]

  const certificationDocument = workflow.certificationDocument
  const workflowStatus = getWorkflowStatus(workflow)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const cancellationEnabled = statusChangesEnabled && (workflowStatus === WorkflowStatus.IN_PROGRESS || workflowStatus === WorkflowStatus.ACTIVE)
  const cancellationText = "Click to cancel this workflow"

  const certExpiresSoon = certificationDocument?.expiresAt && dayjs(certificationDocument.expiresAt).isBefore(dayjs().add(EXPIRES_SOON_DAYS, "day"))

  const humanizeWorkflowStatus = (status: WorkflowStatus): string => {
    if (workflowStatus === WorkflowStatus.ACTIVE && certExpiresSoon) {
      return `ACTIVE (EXPIRES SOON)`
    }

    return status
  }

  const getTooltipText = (): string => {
    if (cancellationEnabled) return cancellationText

    const expirationDate = certificationDocument?.expiresAt
    const isExpired = dayjs(expirationDate).isBefore(dayjs())

    if (isExpired) {
      return `Expired ${dayjs(expirationDate).format("M/D/YY")}`
    }

    if (certExpiresSoon && workflowStatus === WorkflowStatus.ACTIVE) {
      return `Expires ${dayjs(expirationDate).format("M/D/YY")} - ${cancellationText}`
    }

    if (workflowStatus === WorkflowStatus.CANCELLED) {
      return workflow.cancellationReason ?? ""
    }

    return ""
  }

  const getBadgeColor = (): BadgeColor => {
    if (workflowStatus === WorkflowStatus.ACTIVE && certExpiresSoon) {
      return BadgeColor.YELLOW
    }

    return badgeColors[workflowStatus]
  }

  return (
    <>
      <Tooltip label={getTooltipText()}>
        <Box width="fit-content">
          <Badge
            color={getBadgeColor()}
            onClick={(e) => {
              if (!cancellationEnabled) return

              e.stopPropagation()
              onOpen()
            }}
          >
            {humanizeWorkflowStatus(workflowStatus)}
          </Badge>
        </Box>
      </Tooltip>
      {isOpen && <CancellationModal onClose={onClose} workflowId={workflow.id} />}
    </>
  )
}

export default ExpertDeterminationStatusBadge
