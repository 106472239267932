import useSWR from "swr"
import { getConMonJobRunsByWorkflowUuid, QueryParams } from "pages/api/models/conMonJobRuns/queries/getConMonJobRunsByWorkflowUuid"

import { cacheKey, returnedQuery } from "../types"

export default function useGetConMonJobRuns(params: QueryParams, cacheKey: cacheKey): returnedQuery {
  const { data, error, isLoading } = useSWR([params, cacheKey], ([params]) => {
    if (!params.workflowUuid) {
      return
    }

    return getConMonJobRunsByWorkflowUuid(params)
  })

  return {
    conMonJobRuns: data?.conMonJobRuns,
    hasMore: data?.hasMore,
    totalCount: data?.totalCount,
    isLoading,
    isError: !!error,
    error,
  }
}
