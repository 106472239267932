import React, { useState, useCallback, useRef, useEffect } from "react"

import { Button, Checkbox, Menu, MenuButton, MenuList, MenuItem, Text, Input, VStack } from "@chakra-ui/react"
import { H4 } from "src/components/Text"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"

interface Option {
  label: string
  value: string
}

interface MultiSelectDropdownProps {
  availableOptions: (Option | string)[]
  onChange: (value: string[]) => void
  value: string[]
  compact?: boolean
}

const MultiSelectDropdown = ({ availableOptions, onChange, value, compact = false }: MultiSelectDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)

  const normalizedOptions = availableOptions.map((option): Option => {
    if (typeof option === "string") {
      return { label: option, value: option }
    }
    return option
  })

  const handleOptionToggle = useCallback(
    (option: Option) => {
      const newValue = value.includes(option.value) ? value.filter((item) => item !== option.value) : [...value, option.value]

      onChange(newValue)
    },
    [value, onChange],
  )

  const getDisplayText = useCallback(() => {
    if (value.length === 0) return "Select options"
    return value.map((v) => normalizedOptions.find((opt) => opt.value === v)?.label ?? v).join(", ")
  }, [value, normalizedOptions])

  const filteredOptions = normalizedOptions.filter((option) => option.label.toLowerCase().includes(searchTerm.toLowerCase()))

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isOpen])

  useEffect(() => {
    const handleFocus = (event) => {
      if (isOpen && inputRef.current && !inputRef.current.contains(event.target)) {
        inputRef.current.focus()
      }
    }

    const currentMenuRef = menuRef.current

    if (currentMenuRef) {
      currentMenuRef.addEventListener("focusin", handleFocus)
    }

    return () => {
      if (currentMenuRef) {
        currentMenuRef.removeEventListener("focusin", handleFocus)
      }
    }
  }, [isOpen])

  return (
    <Menu
      closeOnSelect={false}
      onOpen={() => setIsOpen(true)}
      onClose={() => {
        setIsOpen(false)
        setSearchTerm("")
      }}
    >
      {compact ? (
        <MenuButton
          as={Button}
          rightIcon={isOpen ? <FiChevronUp /> : <FiChevronDown />}
          variant="ghost"
          size="sm"
          p={0}
          minW={0}
          width="auto"
          textAlign="center"
          borderRadius="md"
          fontWeight="normal"
          bg="transparent"
          _hover={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
        >
          <H4 style={{ color: "var(--text2)" }}>{value.length > 0 ? `(${value.length})` : "(All)"}</H4>
        </MenuButton>
      ) : (
        <MenuButton
          as={Button}
          rightIcon={isOpen ? <FiChevronUp /> : <FiChevronDown />}
          w="200px"
          textAlign="left"
          bg="gray.100"
          _hover={{ bg: "gray.200" }}
          _active={{ bg: "gray.200" }}
          borderRadius="md"
          fontWeight="normal"
        >
          <Text fontSize="md" isTruncated>
            {getDisplayText()}
          </Text>
        </MenuButton>
      )}
      <MenuList py={compact ? 1 : 2} borderRadius="md" shadow="md" ref={menuRef} minW={compact ? "150px" : "200px"}>
        <VStack align="stretch" spacing={compact ? 1 : 2}>
          <Input
            placeholder="Filter..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            mx={2}
            width="90%"
            ref={inputRef}
            onBlur={(e) => {
              // Prevent immediate blur to allow clicking on menu items
              e.preventDefault()

              if (inputRef.current) {
                setTimeout(() => {
                  if (inputRef.current) {
                    inputRef.current.focus()
                  }
                }, 0)
              }
            }}
          />
          {filteredOptions.map((option) => (
            <MenuItem
              key={option.value}
              onClick={(e) => {
                e.preventDefault()
                handleOptionToggle(option)
                if (inputRef.current) inputRef.current.focus()
              }}
              _hover={{ bg: "gray.100" }}
              _focus={{ bg: "gray.100" }}
            >
              <Checkbox isChecked={value.includes(option.value)} onChange={() => {}} colorScheme="blue" mr={3}>
                {option.label}
              </Checkbox>
            </MenuItem>
          ))}
        </VStack>
      </MenuList>
    </Menu>
  )
}

export default MultiSelectDropdown
