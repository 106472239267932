import { useEffect, useRef, useState } from "react"

import { Box, FormControl, FormErrorMessage, Input, Text, CloseButton, HStack, Divider } from "@chakra-ui/react"
import Select2 from "react-select"
import { Formik, Form, Field, FieldArray, FormikErrors, FormikProps } from "formik"
import axios from "axios"
import BasicModal from "src/components/MenusAndModals/BasicModal"
import { handleClientSideException } from "lib/errors"
import revalidateSWRQueryByCacheKey from "src/helpers/revalidateSWRQueryByCacheKey"
import { DEFAULT_ALLOWED_FILE_TYPES, FileType, getFileBaseName, humanizeSupportedFileTypes, maxFileSizeBytes, maxFilesPerUpload, validateMultipleFiles } from "lib/files"
import { useToastContext } from "src/hooks/useToastContext"
import type { IntakeRequirement } from "@prisma/client"

import { getFilePickerMask } from "../UserInteractable/FileDropzone"
import { FileDropzone } from "../UserInteractable/FileDropzone"
import { humanizeRequirementType } from "./IntakeDocumentRow"

interface FormValues {
  documents: {
    documentName: string
    intakeRequirementId: number | null
  }[]
}

interface UploadIntakeDocumentModalProps {
  prefilledFiles?: File[]
  intakeRequirements: IntakeRequirement[]
  dataSourceUuid?: string
  allowedFileTypes?: FileType[]
  onClose: () => void
}

const getDefaultIntakeRequirementId = (intakeRequirements: IntakeRequirement[]) => {
  return intakeRequirements.length === 1 ? intakeRequirements[0]?.id : null
}

const UploadIntakeDocumentModal = ({ prefilledFiles, intakeRequirements, dataSourceUuid, allowedFileTypes = DEFAULT_ALLOWED_FILE_TYPES, onClose }: UploadIntakeDocumentModalProps) => {
  const { showSuccess, showError } = useToastContext()
  const [filesToUpload, setFilesToUpload] = useState([] as File[])
  const formikRef = useRef<FormikProps<FormValues> | null>(null)

  const availableIntakeRequirements = intakeRequirements.filter((requirement) => requirement.type !== "data_management_and_handling_confirmation")

  const validateDocumentName = (value: string): string | null => {
    if (!value.trim()) {
      return "Name is required"
    }
    return null
  }

  const validateRequirement = (value: number | null): string | null => {
    if (!value) {
      return "Category is required"
    }
    return null
  }

  useEffect(() => {
    if (prefilledFiles && formikRef.current) {
      const errors = validateMultipleFiles(0, prefilledFiles)

      if (errors && errors.length) {
        showError(errors[0] || "Unknown error trying to upload file")
        onClose()
        return
      }

      setFilesToUpload(prefilledFiles)
      void formikRef.current.setFieldValue(
        "documents",
        prefilledFiles.map((file) => ({
          documentName: getFileBaseName(file),
          intakeRequirementId: getDefaultIntakeRequirementId(availableIntakeRequirements),
        })),
      )
    }
  }, [onClose, prefilledFiles, showError, availableIntakeRequirements])

  return (
    <BasicModal
      size="4xl"
      hideCloseButton={true}
      onClose={onClose}
      isCentered
      showActionButtons={filesToUpload && filesToUpload.length > 0}
      actionText="Upload"
      onAction={() => formikRef.current?.submitForm()}
      isActionLoading={formikRef.current?.isSubmitting}
      isActionDisabled={!formikRef.current?.values?.documents?.every((doc) => doc.intakeRequirementId)}
    >
      <Formik<FormValues>
        initialValues={{
          documents: [],
        }}
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, actions) => {
          try {
            const documentsWithFilesAttached = values.documents.map((document, index) => ({
              documentName: document.documentName,
              intakeRequirementId: document.intakeRequirementId,
              file: filesToUpload[index],
              ...(dataSourceUuid ? { dataSourceUuid } : {}),
            }))

            await axios.postForm(`/api/intake-documents`, {
              documents: documentsWithFilesAttached,
            })

            await revalidateSWRQueryByCacheKey("getOrganization")
            await revalidateSWRQueryByCacheKey("getDataSourceByUuid")
            showSuccess("File uploaded successfully")
            onClose()
          } catch (error) {
            handleClientSideException(error, "Unable to upload intake document", showError)
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {(props) => (
          <Form>
            {filesToUpload && filesToUpload.length > 0 && (
              <>
                <HStack justify="space-between" fontSize="14px" borderBottom="1px solid var(--ui-color-card-border)" paddingBottom="12px" paddingTop="12px">
                  <Text flex="1.3" color="var(--text2)">
                    Name
                  </Text>
                  <Text flex="1" color="var(--text2)">
                    Category
                  </Text>
                </HStack>

                <FieldArray
                  name="documents"
                  render={(arrayHelpers) => (
                    <Box>
                      {filesToUpload.map((file, index) => (
                        <Box key={index} paddingTop="12px">
                          <HStack align="flex-start">
                            <Box flex="1.3">
                              <FormControl
                                isInvalid={
                                  !!(
                                    props.errors?.documents?.[index] as FormikErrors<{
                                      documentName: string
                                    }>
                                  )?.documentName
                                }
                              >
                                <Field name={`documents.${index}.documentName`} validate={validateDocumentName}>
                                  {({ field }) => <Input {...field} placeholder="Enter document name..." isDisabled={true} />}
                                </Field>
                                <FormErrorMessage>
                                  {
                                    (
                                      props.errors?.documents?.[index] as FormikErrors<{
                                        documentName: string
                                      }>
                                    )?.documentName
                                  }
                                </FormErrorMessage>
                              </FormControl>
                            </Box>

                            <Box flex="1">
                              <HStack>
                                <FormControl
                                  isInvalid={
                                    !!(
                                      props.errors?.documents?.[index] as FormikErrors<{
                                        intakeRequirementId: string
                                      }>
                                    )?.intakeRequirementId
                                  }
                                >
                                  <Field name={`documents.${index}.intakeRequirementId`} validate={validateRequirement}>
                                    {({ field }) => (
                                      <Select2
                                        id={`documents.${index}.intakeRequirementId`}
                                        options={availableIntakeRequirements?.map((requirement) => ({
                                          value: requirement.id,
                                          label: humanizeRequirementType(requirement.type),
                                        }))}
                                        onChange={(selectedOption) =>
                                          field.onChange({
                                            target: {
                                              name: `documents.${index}.intakeRequirementId`,
                                              value: selectedOption?.value,
                                            },
                                          })
                                        }
                                        onBlur={field.onBlur}
                                        value={intakeRequirements
                                          ?.map((requirement) => ({
                                            value: requirement.id,
                                            label: humanizeRequirementType(requirement.type),
                                          }))
                                          .find((option) => option.value === field.value)}
                                        placeholder="Select category"
                                        isDisabled={availableIntakeRequirements.length === 1}
                                      />
                                    )}
                                  </Field>
                                  <FormErrorMessage>
                                    {
                                      (
                                        props.errors?.documents?.[index] as FormikErrors<{
                                          intakeRequirementId: string
                                        }>
                                      )?.intakeRequirementId
                                    }
                                  </FormErrorMessage>
                                </FormControl>
                                <CloseButton
                                  onClick={() => {
                                    arrayHelpers.remove(index)
                                    const newFiles = [...filesToUpload]

                                    newFiles.splice(index, 1)
                                    setFilesToUpload(newFiles)
                                  }}
                                />
                              </HStack>
                            </Box>
                          </HStack>
                          {index !== filesToUpload.length - 1 && <Divider style={{ paddingBottom: "12px" }} />}
                        </Box>
                      ))}
                    </Box>
                  )}
                />
              </>
            )}

            {filesToUpload.length < maxFilesPerUpload && (
              <Box mt={4}>
                <Field name="file">
                  {({ field, form }) => (
                    <FormControl isInvalid={form.errors.file}>
                      <FileDropzone
                        onDrop={(files) => {
                          const errors = validateMultipleFiles(filesToUpload.length, files)

                          if (errors && errors.length) {
                            form.setFieldError("file", errors[0])
                            return
                          }

                          form.setFieldError("file", undefined)
                          form.setFieldValue("documents", [
                            ...(form.values.documents || []),
                            ...files.map((file) => ({
                              documentName: getFileBaseName(file),
                              intakeRequirementId: getDefaultIntakeRequirementId(availableIntakeRequirements),
                            })),
                          ])

                          setFilesToUpload((prevFiles) => [...prevFiles, ...files])
                        }}
                        filePickerMask={getFilePickerMask(allowedFileTypes)}
                        height="200px"
                        warningText={
                          <span style={{ fontSize: "14px", color: "grey" }}>
                            Max size: {maxFileSizeBytes / 1_000_000} MB. Allowed file types: {humanizeSupportedFileTypes(allowedFileTypes)}
                          </span>
                        }
                      />
                      <FormErrorMessage>{form.errors.file}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </BasicModal>
  )
}

export default UploadIntakeDocumentModal
