import { Box } from "@chakra-ui/react"

const EmptyStatePlaceholder = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box width="100%" backgroundColor="whitesmoke" border="1px solid lightgray" borderRadius="4px" display="flex" alignItems="center" justifyContent="center" height="200px" marginTop="12px">
      {children}
    </Box>
  )
}

export default EmptyStatePlaceholder
