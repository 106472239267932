import { useState } from "react"

import dayjs from "dayjs"
import { FiDownload, FiEye } from "react-icons/fi"
import { Box, HStack, Text, useDisclosure } from "@chakra-ui/react"
import { CertificationDocument, DataTable, File, Workflow } from "@prisma/client"
import { useRouter } from "next/router"
import { MdOutlineHistory } from "react-icons/md"
import useGetConMonJobRuns from "src/hooks/getConMonJobRuns/useGetConMonJobRuns"
import { shortenCopyDisplay } from "src/helpers/shortenCopyDisplay"
import useGetFeatureFlagState from "src/hooks/getFeatureFlags/useGetFeatureFlagState"
import { useToastContext } from "src/hooks/useToastContext"
import { handleClientSideException } from "lib/errors"
import useGetWorkflowByUuid from "src/hooks/getWorkflows/useGetWorkflowByUuid"
import { useIntegralOrganizationId } from "src/hooks/useIntegralOrganizationId"

import Tooltip from "../Indicators/Tooltip"
import FilePreviewModal from "../UserInteractable/FilePreviewModal"
import ExpertDeterminationStatusBadge from "./StatusBadge"
import TertiaryButton from "../Buttons/Tertiary"

export type ExtendedWorkflow = Workflow & {
  dataTables: DataTable[]
  certificationDocument: CertificationDocument & { files: File[] }
  organizations: {
    isLead: boolean
    organizationId: number
    Organization: {
      name: string
    }
  }[]
}

const ExpertDeterminationRow = ({ expertDetermination }: { expertDetermination: ExtendedWorkflow }) => {
  const integralOrganizationId = useIntegralOrganizationId()
  const { data: flagState } = useGetFeatureFlagState("app:getFeatureFlagState")
  const rbacEnabled = flagState?.state["role-based-access-control-july-11"]
  const intakeEnabled = flagState?.state["intake-nov-13"]

  const { workflow } = useGetWorkflowByUuid({ workflowUuid: expertDetermination.uuid }, "app:getWorkflow")
  const hasIntakeRequirements = workflow?.intakeRequirements?.length > 0

  const humanizeDatasets = (dataTables: DataTable[]) => {
    return dataTables.map((dataTable) => dataTable.name).join(" + ")
  }

  const combinedDatasets = humanizeDatasets(expertDetermination.dataTables)
  const [minifiedDatasets, datasetsWereShortened] = shortenCopyDisplay(combinedDatasets, 30)

  const router = useRouter()

  const { showError } = useToastContext()

  const { isOpen: isPreviewOpen, onOpen: onPreviewOpen, onClose: onPreviewClose } = useDisclosure()
  const [blobSource, setBlobSource] = useState("")
  const [blob, setBlob] = useState<Blob>()
  const [previewFile, setPreviewFile] = useState(null as any)

  const certificationDocument = expertDetermination.certificationDocument
  const file = certificationDocument?.files[0]

  // Fetch the most recent runs separately
  const { conMonJobRuns: mostRecentRuns } = useGetConMonJobRuns(
    {
      workflowUuid: expertDetermination.uuid,
      skip: 0,
      take: 1,
    },
    `data-history:getMostRecentRuns`,
  )

  const getLeadOrgName = () => {
    const leadOrg = (expertDetermination.organizations || []).find((org) => org.isLead && org.organizationId !== integralOrganizationId)

    return leadOrg?.Organization.name || "Integral"
  }

  const formatLastUpdated = (date) => {
    return dayjs(date).format("M/D/YY")
  }

  const handlePreviewClose = async (): Promise<void> => {
    onPreviewClose()

    setBlobSource("")
  }

  const handleClickPreview = async (e): Promise<void> => {
    e.stopPropagation()

    if (!file) return

    onPreviewOpen()

    try {
      const response = await fetch(`/api/files/${file.reference}`)

      if (response.status !== 200) {
        throw new Error("There was an issue previewing the file")
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      setBlobSource(url)
      setBlob(blob)
      setPreviewFile(file)
    } catch (error) {
      handleClientSideException(error, "There was an issue previewing the file", showError)
    }
  }

  const handleClickDownload = async (e): Promise<void> => {
    e.stopPropagation()

    if (!file) return

    try {
      // We use fetch instead of axios in these two click handlers due to the .blob() method on the native fetch response object.
      const response = await fetch(`/api/files/${file.reference}`)

      if (response.status !== 200) {
        throw new Error("There was an issue downloading the file")
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")

      a.href = url
      a.download = file.name
      a.click()
    } catch (error) {
      handleClientSideException(error, "There was an issue downloading the file", showError)
    }
  }

  const handleHistoryClick = async (e: React.MouseEvent) => {
    e.stopPropagation() // Prevent the row click event from firing

    await router.push(`/app/workflow/${expertDetermination.uuid}/data-history`)
  }

  return (
    <tr
      style={{
        borderTop: "1px solid var(--card-border)",
        cursor: "pointer",
      }}
      onClick={async () => {
        await router.push(intakeEnabled && hasIntakeRequirements ? `/app/workflow/${expertDetermination.uuid}/requirements` : `/app/workflow/${expertDetermination.uuid}/use-case`)
      }}
    >
      <td
        style={{
          padding: "20px 0px",
          paddingLeft: "24px",
          maxWidth: "300px",
        }}
      >
        {expertDetermination.name}
      </td>

      {rbacEnabled && (
        <td
          style={{
            padding: "12px 0px",
          }}
        >
          {getLeadOrgName()}
        </td>
      )}

      <td
        style={{
          padding: "12px 0px",
        }}
      >
        <ExpertDeterminationStatusBadge workflow={expertDetermination} />
      </td>

      <td
        style={{
          padding: "12px 0px",
        }}
      >
        {datasetsWereShortened ? (
          <Tooltip label={combinedDatasets}>
            <Text>{minifiedDatasets}</Text>
          </Tooltip>
        ) : (
          <Text>{combinedDatasets}</Text>
        )}
      </td>

      <td
        style={{
          padding: "12px 0px",
        }}
      >
        {/* if mostRecentRuns is not empty, show the last updated date of the most recent runs */}
        {mostRecentRuns?.length > 0 ? (
          <Box display="flex" alignItems="center">
            <Text mr="6px">{formatLastUpdated(mostRecentRuns[0].updatedAt)}</Text>

            <Tooltip label="Continuous monitoring: View Data History">
              <TertiaryButton padding="0px" onClick={handleHistoryClick}>
                <MdOutlineHistory size="20px" />
              </TertiaryButton>
            </Tooltip>
          </Box>
        ) : certificationDocument ? (
          formatLastUpdated(certificationDocument.certifiedAt)
        ) : (
          "N/A"
        )}
      </td>

      <td
        style={{
          padding: "12px 0px",
        }}
      >
        {expertDetermination.certificationDocument && (
          <HStack>
            <Tooltip label="Preview report">
              <TertiaryButton padding="0px" onClick={handleClickPreview}>
                <FiEye />
              </TertiaryButton>
            </Tooltip>
            <Tooltip label="Download report">
              <TertiaryButton padding="0px" onClick={handleClickDownload}>
                <FiDownload />
              </TertiaryButton>
            </Tooltip>
          </HStack>
        )}
        {isPreviewOpen && <FilePreviewModal onClose={handlePreviewClose} blob={blob} blobSource={blobSource} file={previewFile} />}
      </td>
    </tr>
  )
}

export default ExpertDeterminationRow
