import { FiPlus } from "react-icons/fi"
import { useDisclosure } from "@chakra-ui/react"
import type { IntakeRequirement } from "@prisma/client"
import { FileType } from "lib/files"

import SecondaryButton from "../Buttons/Secondary"
import UploadIntakeDocumentModal from "./UploadIntakeDocumentModal"

const NewUploadCard = ({ intakeRequirements, allowedFileTypes, dataSourceUuid }: { intakeRequirements: IntakeRequirement[]; allowedFileTypes?: FileType[]; dataSourceUuid?: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <SecondaryButton onClick={onOpen} leftIcon={<FiPlus />}>
        New upload
      </SecondaryButton>

      {isOpen && <UploadIntakeDocumentModal intakeRequirements={intakeRequirements} allowedFileTypes={allowedFileTypes} dataSourceUuid={dataSourceUuid} onClose={onClose} />}
    </>
  )
}

export default NewUploadCard
